import {useEffect, useState} from 'react'

import api from 'utility/api'


const Contact = _ => {
    const [ form, update_form ] = useState({ name: ``, email: ``, message: ``, success: false, errors: [ ] })

    const update_form_input = field => ({ target }) => update_form(current => ({ ...current, [ field ]: target.value }))

    const send = event => {
        event.preventDefault()
        update_form(current => ({ ...current, success: undefined }))
    }

    useEffect(_ => {
        if (form.success === undefined) {
            api.post(`send`, {
                payload: ({ name: form.name, email: form.email, message: form.message }),
                resolve: ({ data }) => {
                    if (data.success) {
                        update_form({name: ``, email: ``, message: ``, success: true, errors: []})
                    } else {
                        update_form(current => ({...current, success: false, errors: data.errors}))
                    }
                }
            })
        }
    }, [ form ])

    return (
        <section id="contact">
            <header>
                <h2>CONTACT</h2>
            </header>
            <form>
                {
                    form.success
                        ? <p>Message sent!</p>
                        : form.success === undefined
                        ? <p>Loading...</p>
                        : (
                            <ul>
                                { form.errors.map(({ param, msg }) => <li key={ `contact_'${ param }'_'${ msg }'` }>- { param[ 0 ].toUpperCase() }{ param.slice(1) } { msg.toLowerCase() }</li>) }
                            </ul>
                        )
                }
                <label htmlFor="name">Name</label>
                <input id="name" name="name" type="text" onChange={ update_form_input(`name`) } value={ form.name } placeholder="John Smith"/>
                <label htmlFor="email">Email</label>
                <input id="email" name="email" type="text" onInput={ update_form_input(`email`) } value={ form.email } placeholder="john.smith123@gmail.com"/>
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" rows={ 7 } onInput={ update_form_input(`message`) } value={ form.message } placeholder="Type your message here..."/>
                <button type="submit" onClick={ send }><i className="fas fa-envelope"/></button>
            </form>
        </section>
    )
}


export default Contact
