const Portfolio = _ => {
    return (
        <section id="portfolio">
            <header>
                <h2>PORTFOLIO</h2>
            </header>
            <div>
                <article>
                    <img src="/file/image/abstract/min/abstract4.jpg" alt="background"/>
                    <div>
                        <h3>E-commerce</h3>
                        <ul>
                            <li>Java</li>
                            <li>Spring</li>
                            <li>React</li>
                            <li>Bootstrap</li>
                            <li>Rest</li>
                        </ul>
                        <ul>
                            <li>account system</li>
                            <li>catalogue system</li>
                            <li>search system</li>
                            <li>score system</li>
                            <li>review system</li>
                            <li>payment system</li>
                        </ul>
                        <nav>
                            <a href="https://gitlab.com/ZarimirMitev/e-commerce"><span><i className="fab fa-git"/></span></a>
                            <a href="https://ZarimirMitev.com/project/e-commerce"><span><i className="fas fa-eye"/></span></a>
                        </nav>
                    </div>
                </article>
                <article>
                    <img src="/file/image/abstract/min/abstract5.jpg" alt="background"/>
                    <div>
                        <h3>Food Delivery</h3>
                        <ul>
                            <li>C#</li>
                            <li>ASP.NET</li>
                            <li>Angular</li>
                            <li>Tailwind</li>
                            <li>Rest</li>
                        </ul>
                        <ul>
                            <li>account system</li>
                            <li>catalogue system</li>
                            <li>search system</li>
                            <li>payment system</li>
                        </ul>
                        <nav>
                            <a href="https://gitlab.com/ZarimirMitev/food-delivery"><span><i className="fab fa-git"/></span></a>
                            <a href="https://ZarimirMitev.com/project/food-delivery"><span><i className="fas fa-eye"/></span></a>
                        </nav>
                    </div>
                </article>
                <article>
                    <img src="/file/image/abstract/min/abstract2.jpg" alt="background"/>
                    <div>
                        <h3>Social News</h3>
                        <ul>
                            <li>Python</li>
                            <li>Django</li>
                            <li>Vue</li>
                            <li>Tailwind</li>
                            <li>Rest</li>
                        </ul>
                        <ul>
                            <li>account system</li>
                            <li>search system</li>
                            <li>post system</li>
                            <li>comment system</li>
                        </ul>
                        <nav>
                            <a href="https://gitlab.com/ZarimirMitev/social-news"><span><i className="fab fa-git"/></span></a>
                            <a href="https://ZarimirMitev.com/project/social-news"><span><i className="fas fa-eye"/></span></a>
                        </nav>
                    </div>
                </article>
            </div>
        </section>
    )
}


export default Portfolio
