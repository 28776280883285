const Title = _ => {
    return (
        <header id="title">
            <h1>Zarimir Mitev</h1>
            <p>Full Stack Developer & Private Teacher</p>
        </header>
    )
}


export default Title
