import { HashLink } from 'react-router-hash-link'


const About = _ => {
    return (
        <section id="about">
            <header>
                <h2>About</h2>
                <p>I'm a full stack web developer with a CS degree and five years of experience teaching computer science and mathematics to university students. Studying at an international university taught me to be organised, adaptable and to thrive in a dynamic environment.</p>
                <nav>
                    <HashLink to="/page/home#portfolio" smooth>Portfolio</HashLink>
                    <a href="/file/pdf/resume.pdf">Resume</a>
                    <HashLink to="/page/home#contact" smooth>Contact</HashLink>
                </nav>
            </header>
            <div>
                <article>
                    <p><i className="fas fa-graduation-cap"/></p>
                    <h3>Teaching</h3>
                    <ul>
                        <li>Probability Theory</li>
                        <li>Graph Theory</li>
                        <li>Theory of Computation</li>
                        <li>Linear Algebra</li>
                        <li>Abstract Algebra</li>
                    </ul>
                </article>
                <article>
                    <p><i className="fas fa-tools"/></p>
                    <h3>Backend</h3>
                    <ul>
                        <li>Java Spring</li>
                        <li>C# ASP.NET</li>
                        <li>Python Django</li>
                        <li>Node Express</li>
                        <li>SQL</li>
                    </ul>
                </article>
                <article>
                    <p><i className="fas fa-pencil-ruler"/></p>
                    <h3>Frontend</h3>
                    <ul>
                        <li>React</li>
                        <li>Angular</li>
                        <li>Vue</li>
                        <li>Bootstrap</li>
                        <li>Tailwind</li>
                    </ul>
                </article>
            </div>
        </section>
    )
}


export default About
